import React from "react";

export interface TutorialProps {
    children?: Element;
}

export default function Tutorials(props: TutorialProps) {
    return (
        <div>Tutorials</div>
    );
}