import React from 'react';
import './App.css';
import Header  from './components/header/Header'
import Footer  from './components/footer/Footer'
import HeaderSection  from './components/headerSection/HeaderSection'
import ContentSection  from './components/contentSection/ContentSection'
import Blog from './pages/Blog/Blog';

function App() {
  return (
    <div className="App">
      <Header/>
      <HeaderSection/>
      <ContentSection/>
      <Blog/>
      <Footer/>
    </div>
  );
}

export default App;
